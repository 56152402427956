<template>
  <div
    class="hp-header-text-info col col-lg-14 col-xl-16 hp-header-start-text d-flex align-items-center"
  >
    <div
      class="d-flex rounded-lg hp-text-color-primary-1 hp-text-color-dark-0 p-12 hp-bg-color-primary-4 hp-bg-color-dark-70"
      style="max-width: 50px"
    >
      <i
        v-if="isMaintenance"
        class="ri-error-warning-line text-warning"
        style="zoom: 150%"
      ></i>
      <i v-else class="ri-emotion-happy-line" style="zoom: 150%"></i>
    </div>

    <p
      v-if="isMaintenance"
      class="hp-header-start-text-item hp-input-label text-warning ml-16 mb-0 lh-1 d-flex align-items-center"
    >
      Hello Admin! We are currently in maintenance mode but you can still access
      the site.
    </p>
    <p
      v-else
      class="hp-header-start-text-item hp-input-label hp-text-color-black-100 hp-text-color-dark-0 ml-16 mb-0 lh-1 d-flex align-items-center"
    >
      Welcome! Please do not consider what you see as a final version.
    </p>
  </div>
</template>

<script>
import lowauthHeader from "../../../services/lowauth-header";
import axios from "axios";

export default {
  name: "HeaderText",
  data() {
    return {
      isMaintenance: false,
    };
  },
  async mounted() {
    var access_key = JSON.parse(localStorage.getItem("user")).accessToken;
    var refresh_key = JSON.parse(localStorage.getItem("user")).refreshToken;

    var data = {
      access_key: access_key,
      refresh_key: refresh_key,
    };
    await axios
      .post("https://api-unp.sacul.cloud/auth/vitals", data, {
        headers: lowauthHeader(),
      })
      .then((res) => {
        this.isMaintenance = res.data.maintenance;
      });
  },
};
</script>
