<template>
  <div class="hp-header-logo d-flex align-items-end">
    <b-link to="/">
      <img
        v-if="this.$store.state.themeConfig.theme == 'light'"
        class="hp-logo"
        v-bind:src="require('@/assets/img/logo/unoobsportal_logo.png')"
        alt="logo"
      />
      <img
        v-else-if="this.$store.state.themeConfig.theme == 'dark'"
        class="hp-logo"
        v-bind:src="require('@/assets/img/logo/unoobsportal_logo.png')"
        alt="logo"
      />
    </b-link>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
