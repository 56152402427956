<template>
  <footer
    class="w-100 py-18 px-16 py-sm-24 px-sm-32 hp-bg-color-black-10 hp-bg-color-dark-100"
  >
    <div class="row align-items-center">
      <div class="col-12 col-sm-6">
        <p
          class="hp-badge-text mb-0 text-center text-sm-left hp-text-color-dark-30"
        >
          COPYRIGHT ©2022 Undisputed Noobs, All rights Reserved
        </p>
      </div>

      <div class="col-12 col-sm-6 mt-8 mt-sm-0 text-center text-sm-right">
        <a class="hp-badge-text hp-text-color-dark-30">
          Version: {{ this.$store.state.themeConfig.version }}
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
import { BModal, BButton } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
